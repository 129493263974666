// stylelint-disable declaration-no-important

// Width and height

// Needed to import again, otherwise mixin media-breakpoint-up is not found!?
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $prop, $abbrev in (width: w, height: h) {
			@each $size, $length in $sizes {
				.#{$abbrev}#{$infix}-#{$size} {
					#{$prop}: $length !important;
				}
			}
		}
	}
}

.mw-100 {
	max-width: 100% !important;
}
.mh-100 {
	max-height: 100% !important;
}

