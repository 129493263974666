// TAN TABLES
.tb-filter-wrapper {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	gap: 2px;
	max-width: 200px;
	font-size: 0.9rem;
	background-color: white;
	border-radius: 5px;
	width: 100%;
	min-width: 30px;
	outline-offset: -3px;

	&:focus-within {
		outline: 1px solid var(--mat-sys-primary);
	}

	&.filter-active {
		outline: 2px solid var(--mat-sys-tertiary);
	}

	& > .select-input {
		padding-left: 4px;
		font-size: inherit;
		min-width: 30px;
		width: auto;
		max-width: 126px;
		outline: none;
		background-color: transparent;
		border: none;
	}

	& > input {
		padding-left: 4px;
		min-width: 30px;
		font-size: inherit;
		width: auto;
		max-width: 126px;
		outline: none;
		background-color: transparent;
		border: none;
	}

	& > button {
		width: 20px;
		height: 20px;
		font-size: 20px;

		& > mat-icon {
			height: 20px;
			width: 20px;
			font-size: 20px;
		}
	}

	& > button.filter-reset-button {
		color: var(--mat-sys-tertiary);
	}
}

.icon-button-small {
	width: 24px;
	height: 24px;
	font-size: 24px;

	& > mat-icon {
		height: 24px;
		width: 24px;
		font-size: 24px;
	}

	&:disabled > mat-icon {
		color: var(--disabled);
	}
}

.mat-date-range-input-in-tb-header {
	.mat-date-range-input-container {
		width: 100%;
		height: 100%;

		.mat-date-range-input-wrapper {
			flex: 1 1 1px;
			width: 1px;
		}

		& input {
			border: 1px solid var(--mat-sys-primary);
			color: #000000de;
			font-weight: normal;
			border-radius: 3px;

			&.date-error {
				border: 1px solid red;
			}
		}
	}
}

.tb-options-dialog.tb-options-dialog {
	min-width: 300px !important;
}

@media only screen and (min-width: 767px) {
	.tb-options-dialog.tb-options-dialog {
		min-width: 600px !important;
	}
}
