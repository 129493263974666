@use '@angular/material' as mat;

html {
	/* COLOR SYSTEM VARIABLES */
	color-scheme: light;

	/* Primary palette variables */
	--mat-sys-primary: light-dark(#7a5649, #ebbcac);
	--mat-sys-on-primary: light-dark(#ffffff, #46291e);
	--mat-sys-primary-container: light-dark(#d7ccc8, #603f33);
	--mat-sys-on-primary-container: light-dark(#2e150b, #d7ccc8);
	--mat-sys-inverse-primary: light-dark(#ebbcac, #7a5649);
	--mat-sys-primary-fixed: light-dark(#d7ccc8, #d7ccc8);
	--mat-sys-primary-fixed-dim: light-dark(#ebbcac, #ebbcac);
	--mat-sys-on-primary-fixed: light-dark(#2e150b, #2e150b);
	--mat-sys-on-primary-fixed-variant: light-dark(#603f33, #603f33);

	/* Secondary palette variables */
	--mat-sys-secondary: light-dark(#9b4056, #ffb2bf);
	--mat-sys-on-secondary: light-dark(#ffffff, #5f1129);
	--mat-sys-secondary-container: light-dark(#ffd9de, #7d293f);
	--mat-sys-on-secondary-container: light-dark(#3f0015, #ffd9de);
	--mat-sys-secondary-fixed: light-dark(#ffd9de, #ffd9de);
	--mat-sys-secondary-fixed-dim: light-dark(#ffb2bf, #ffb2bf);
	--mat-sys-on-secondary-fixed: light-dark(#3f0015, #3f0015);
	--mat-sys-on-secondary-fixed-variant: light-dark(#7d293f, #7d293f);

	/* Tertiary palette variables */
	--mat-sys-tertiary: light-dark(#cc7802, #e4a05b);
	--mat-sys-on-tertiary: light-dark(#ffffff, #4a2800);
	--mat-sys-tertiary-container: light-dark(#ffdcbe, #693c00);
	--mat-sys-on-tertiary-container: light-dark(#2c1600, #ffdcbe);
	--mat-sys-tertiary-fixed: light-dark(#ffdcbe, #ffdcbe);
	--mat-sys-tertiary-fixed-dim: light-dark(#ffb870, #ffb870);
	--mat-sys-on-tertiary-fixed: light-dark(#2c1600, #2c1600);
	--mat-sys-on-tertiary-fixed-variant: light-dark(#693c00, #693c00);

	/* Neutral palette variables */
	--mat-sys-background: light-dark(#fff8f6, #161312);
	--mat-sys-on-background: light-dark(#1e1b1a, #e9e1df);
	--mat-sys-surface: light-dark(#fff8f6, #161312);
	--mat-sys-surface-dim: light-dark(#e0d8d7, #161312);
	--mat-sys-surface-bright: light-dark(#fff8f6, #3c3838);
	--mat-sys-surface-container-low: light-dark(#f5f2f2, #141211);
	--mat-sys-surface-container-lowest: light-dark(#ffffff, #100e0d);
	--mat-sys-surface-container: light-dark(#f4eceb, #221f1e);
	--mat-sys-surface-container-high: light-dark(#eee6e5, #2d2929);
	--mat-sys-surface-container-highest: light-dark(#e9e1df, #383433);
	--mat-sys-on-surface: light-dark(#1e1b1a, #e9e1df);
	--mat-sys-shadow: light-dark(#000000, #000000);
	--mat-sys-scrim: light-dark(#000000, #000000);
	--mat-sys-surface-tint: light-dark(#7a5649, #ebbcac);
	--mat-sys-inverse-surface: light-dark(#33302f, #e9e1df);
	--mat-sys-inverse-on-surface: light-dark(#f7efee, #33302f);
	--mat-sys-outline: light-dark(#82746f, #9d8d88);
	--mat-sys-outline-variant: light-dark(#d4c3bd, #504440);
	--mat-sys-neutral10: light-dark(#1e1b1a, #1e1b1a); /* Variable used for the form field native select option text color */

	/* Error palette variables */
	--mat-sys-error: light-dark(#ba1a1a, #ffb4ab);
	--mat-sys-on-error: light-dark(#ffffff, #690005);
	--mat-sys-error-container: light-dark(#ffdad6, #93000a);
	--mat-sys-on-error-container: light-dark(#410002, #ffdad6);

	/* Neutral variant palette variables */
	--mat-sys-surface-variant: light-dark(#f1dfd9, #504440);
	--mat-sys-on-surface-variant: light-dark(#504440, #d4c3bd);
	--mat-sys-neutral-variant20: light-dark(
		#392e2a,
		#392e2a
	); /* Variable used for the sidenav scrim (container background shadow when opened) */

	/* TYPOGRAPHY SYSTEM VARIABLES */

	/* Typography variables. Only used in the different typescale system variables. */
	--mat-sys-brand-font-family: Roboto; /* The font-family to use for brand text. */
	--mat-sys-plain-font-family: Roboto; /* The font-family to use for plain text. */
	--mat-sys-bold-font-weight: 700; /* The font-weight to use for bold text. */
	--mat-sys-medium-font-weight: 500; /* The font-weight to use for medium text. */
	--mat-sys-regular-font-weight: 400; /* The font-weight to use for regular text. */

	/* Typescale variables. */
	/* Warning: Risk of reduced fidelity from using the composite typography tokens (ex. --mat-sys-body-large) since
	 tracking cannot be represented in the "font" property shorthand. Consider using the discrete properties instead. */
	--mat-sys-body-large: var(--mat-sys-body-large-weight) var(--mat-sys-body-large-size) / var(--mat-sys-body-large-line-height)
		var(--mat-sys-body-large-font);
	--mat-sys-body-large-font: var(--mat-sys-plain-font-family);
	--mat-sys-body-large-line-height: 1.5rem;
	--mat-sys-body-large-size: 1rem;
	--mat-sys-body-large-tracking: 0.031rem;
	--mat-sys-body-large-weight: var(--mat-sys-regular-font-weight);

	/* Body medium typescale */
	--mat-sys-body-medium: var(--mat-sys-body-medium-weight) var(--mat-sys-body-medium-size) / var(--mat-sys-body-medium-line-height)
		var(--mat-sys-body-medium-font);
	--mat-sys-body-medium-font: var(--mat-sys-plain-font-family);
	--mat-sys-body-medium-line-height: 1.25rem;
	--mat-sys-body-medium-size: 0.875rem;
	--mat-sys-body-medium-tracking: 0.016rem;
	--mat-sys-body-medium-weight: var(--mat-sys-regular-font-weight);

	/* Body small typescale */
	--mat-sys-body-small: var(--mat-sys-body-small-weight) var(--mat-sys-body-small-size) / var(--mat-sys-body-small-line-height)
		var(--mat-sys-body-small-font);
	--mat-sys-body-small-font: var(--mat-sys-plain-font-family);
	--mat-sys-body-small-line-height: 1rem;
	--mat-sys-body-small-size: 0.75rem;
	--mat-sys-body-small-tracking: 0.025rem;
	--mat-sys-body-small-weight: var(--mat-sys-regular-font-weight);

	/* Display large typescale */
	--mat-sys-display-large: var(--mat-sys-display-large-weight) var(--mat-sys-display-large-size) /
		var(--mat-sys-display-large-line-height) var(--mat-sys-display-large-font);
	--mat-sys-display-large-font: var(--mat-sys-brand-font-family);
	--mat-sys-display-large-line-height: 4rem;
	--mat-sys-display-large-size: 3.562rem;
	--mat-sys-display-large-tracking: -0.016rem;
	--mat-sys-display-large-weight: var(--mat-sys-regular-font-weight);

	/* Display medium typescale */
	--mat-sys-display-medium: var(--mat-sys-display-medium-weight) var(--mat-sys-display-medium-size) /
		var(--mat-sys-display-medium-line-height) var(--mat-sys-display-medium-font);
	--mat-sys-display-medium-font: var(--mat-sys-brand-font-family);
	--mat-sys-display-medium-line-height: 3.25rem;
	--mat-sys-display-medium-size: 2.812rem;
	--mat-sys-display-medium-tracking: 0;
	--mat-sys-display-medium-weight: var(--mat-sys-regular-font-weight);

	/* Display small typescale */
	--mat-sys-display-small: var(--mat-sys-display-small-weight) var(--mat-sys-display-small-size) /
		var(--mat-sys-display-small-line-height) var(--mat-sys-display-small-font);
	--mat-sys-display-small-font: var(--mat-sys-brand-font-family);
	--mat-sys-display-small-line-height: 2.75rem;
	--mat-sys-display-small-size: 2.25rem;
	--mat-sys-display-small-tracking: 0;
	--mat-sys-display-small-weight: var(--mat-sys-regular-font-weight);

	/* Headline large typescale */
	--mat-sys-headline-large: var(--mat-sys-headline-large-weight) var(--mat-sys-headline-large-size) /
		var(--mat-sys-headline-large-line-height) var(--mat-sys-headline-large-font);
	--mat-sys-headline-large-font: var(--mat-sys-brand-font-family);
	--mat-sys-headline-large-line-height: 2.5rem;
	--mat-sys-headline-large-size: 2rem;
	--mat-sys-headline-large-tracking: 0;
	--mat-sys-headline-large-weight: var(--mat-sys-regular-font-weight);

	/* Headline medium typescale */
	--mat-sys-headline-medium: var(--mat-sys-headline-medium-weight) var(--mat-sys-headline-medium-size) /
		var(--mat-sys-headline-medium-line-height) var(--mat-sys-headline-medium-font);
	--mat-sys-headline-medium-font: var(--mat-sys-brand-font-family);
	--mat-sys-headline-medium-line-height: 2.25rem;
	--mat-sys-headline-medium-size: 1.75rem;
	--mat-sys-headline-medium-tracking: 0;
	--mat-sys-headline-medium-weight: var(--mat-sys-regular-font-weight);

	/* Headline small typescale */
	--mat-sys-headline-small: var(--mat-sys-headline-small-weight) var(--mat-sys-headline-small-size) /
		var(--mat-sys-headline-small-line-height) var(--mat-sys-headline-small-font);
	--mat-sys-headline-small-font: var(--mat-sys-brand-font-family);
	--mat-sys-headline-small-line-height: 2rem;
	--mat-sys-headline-small-size: 1.5rem;
	--mat-sys-headline-small-tracking: 0;
	--mat-sys-headline-small-weight: var(--mat-sys-regular-font-weight);

	/* Label large typescale */
	--mat-sys-label-large: var(--mat-sys-label-large-weight) var(--mat-sys-label-large-size) / var(--mat-sys-label-large-line-height)
		var(--mat-sys-label-large-font);
	--mat-sys-label-large-font: var(--mat-sys-plain-font-family);
	--mat-sys-label-large-line-height: 1.25rem;
	--mat-sys-label-large-size: 0.875rem;
	--mat-sys-label-large-tracking: 0.006rem;
	--mat-sys-label-large-weight: var(--mat-sys-medium-font-weight);
	--mat-sys-label-large-weight-prominent: var(--mat-sys-bold-font-weight);

	/* Label medium typescale */
	--mat-sys-label-medium: var(--mat-sys-label-medium-weight) var(--mat-sys-label-medium-size) / var(--mat-sys-label-medium-line-height)
		var(--mat-sys-label-medium-font);
	--mat-sys-label-medium-font: var(--mat-sys-plain-font-family);
	--mat-sys-label-medium-line-height: 1rem;
	--mat-sys-label-medium-size: 0.75rem;
	--mat-sys-label-medium-tracking: 0.031rem;
	--mat-sys-label-medium-weight: var(--mat-sys-medium-font-weight);
	--mat-sys-label-medium-weight-prominent: var(--mat-sys-bold-font-weight);

	/* Label small typescale */
	--mat-sys-label-small: var(--mat-sys-label-small-weight) var(--mat-sys-label-small-size) / var(--mat-sys-label-small-line-height)
		var(--mat-sys-label-small-font);
	--mat-sys-label-small-font: var(--mat-sys-plain-font-family);
	--mat-sys-label-small-line-height: 1rem;
	--mat-sys-label-small-size: 0.688rem;
	--mat-sys-label-small-tracking: 0.031rem;
	--mat-sys-label-small-weight: var(--mat-sys-medium-font-weight);

	/* Title large typescale */
	--mat-sys-title-large: var(--mat-sys-title-large-weight) var(--mat-sys-title-large-size) / var(--mat-sys-title-large-line-height)
		var(--mat-sys-title-large-font);
	--mat-sys-title-large-font: var(--mat-sys-brand-font-family);
	--mat-sys-title-large-line-height: 1.75rem;
	--mat-sys-title-large-size: 1.375rem;
	--mat-sys-title-large-tracking: 0;
	--mat-sys-title-large-weight: var(--mat-sys-regular-font-weight);

	/* Title medium typescale */
	--mat-sys-title-medium: var(--mat-sys-title-medium-weight) var(--mat-sys-title-medium-size) / var(--mat-sys-title-medium-line-height)
		var(--mat-sys-title-medium-font);
	--mat-sys-title-medium-font: var(--mat-sys-plain-font-family);
	--mat-sys-title-medium-line-height: 1.5rem;
	--mat-sys-title-medium-size: 1rem;
	--mat-sys-title-medium-tracking: 0.009rem;
	--mat-sys-title-medium-weight: var(--mat-sys-medium-font-weight);

	/* Title small typescale */
	--mat-sys-title-small: var(--mat-sys-title-small-weight) var(--mat-sys-title-small-size) / var(--mat-sys-title-small-line-height)
		var(--mat-sys-title-small-font);
	--mat-sys-title-small-font: var(--mat-sys-plain-font-family);
	--mat-sys-title-small-line-height: 1.25rem;
	--mat-sys-title-small-size: 0.875rem;
	--mat-sys-title-small-tracking: 0.006rem;
	--mat-sys-title-small-weight: var(--mat-sys-medium-font-weight);

	/* ELEVATION SYSTEM VARIABLES */

	/* Box shadow colors. Only used in the elevation level system variables. */
	--mat-sys-umbra-color: color-mix(in srgb, var(--mat-sys-shadow), transparent 80%);
	--mat-sys-penumbra-color: color-mix(in srgb, var(--mat-sys-shadow), transparent 86%);
	--mat-sys-ambient-color: color-mix(in srgb, var(--mat-sys-shadow), transparent 88%);

	/* Elevation level system variables. These are used as the value for box-shadow CSS property. */
	--mat-sys-level0: 0px 0px 0px 0px var(--mat-sys-umbra-color), 0px 0px 0px 0px var(--mat-sys-penumbra-color),
		0px 0px 0px 0px var(--mat-sys-ambient-color);
	--mat-sys-level1: 0px 2px 1px -1px var(--mat-sys-umbra-color), 0px 1px 1px 0px var(--mat-sys-penumbra-color),
		0px 1px 3px 0px var(--mat-sys-ambient-color);
	--mat-sys-level2: 0px 3px 3px -2px var(--mat-sys-umbra-color), 0px 3px 4px 0px var(--mat-sys-penumbra-color),
		0px 1px 8px 0px var(--mat-sys-ambient-color);
	--mat-sys-level3: 0px 3px 5px -1px var(--mat-sys-umbra-color), 0px 6px 10px 0px var(--mat-sys-penumbra-color),
		0px 1px 18px 0px var(--mat-sys-ambient-color);
	--mat-sys-level4: 0px 5px 5px -3px var(--mat-sys-umbra-color), 0px 8px 10px 1px var(--mat-sys-penumbra-color),
		0px 3px 14px 2px var(--mat-sys-ambient-color);
	--mat-sys-level5: 0px 7px 8px -4px var(--mat-sys-umbra-color), 0px 12px 17px 2px var(--mat-sys-penumbra-color),
		0px 5px 22px 4px var(--mat-sys-ambient-color);

	/* SHAPE SYSTEM VARIABLES */
	--mat-sys-corner-extra-large: 28px;
	--mat-sys-corner-extra-large-top: 28px 28px 0 0;
	--mat-sys-corner-extra-small: 4px;
	--mat-sys-corner-extra-small-top: 4px 4px 0 0;
	--mat-sys-corner-full: 9999px;
	--mat-sys-corner-large: 16px;
	--mat-sys-corner-large-end: 0 16px 16px 0;
	--mat-sys-corner-large-start: 16px 0 0 16px;
	--mat-sys-corner-large-top: 16px 16px 0 0;
	--mat-sys-corner-medium: 12px;
	--mat-sys-corner-none: 0;
	--mat-sys-corner-small: 8px;

	/* STATE SYSTEM VARIABLES */
	--mat-sys-dragged-state-layer-opacity: 0.16;
	--mat-sys-focus-state-layer-opacity: 0.12;
	--mat-sys-hover-state-layer-opacity: 0.08;
	--mat-sys-pressed-state-layer-opacity: 0.12;

	@include mat.theme(
		(
			// color: (
			// 	primary: m3-colors.$primary-palette,
			// 	tertiary: m3-colors.$tertiary-palette,
			// 	theme-type: light,
			// 	use-system-variables: true,
			// ),
			// typography: (plain-family: Roboto, brand-family: Raleway, bold-weight: 700, medium-weight: 500, regular-weight: 400),
			density: -2
		)
	);
}
