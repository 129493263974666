:root {
	--ae-gap: 5px;
	--ae-text-area-border: 1px solid #ddd;
	--ae-text-area-border-radius: 0;
	--ae-focus-outline-color: #afaeae auto 1px;
	--ae-toolbar-padding: 1px;
	--ae-toolbar-bg-color: var(--mat-sys-secondary-container);
	--ae-toolbar-border-radius: 1px solid #ddd;
	--ae-button-bg-color: #dadad7;
	--ae-button-border: 2px solid var(--mat-sys-secondary);
	--ae-button-radius: 5px;
	--ae-button-hover-bg-color: var(--mat-sys-secondary-container);
	--ae-button-active-bg-color: red;
	--ae-button-active-hover-bg-color: blue;
	--ae-button-disabled-bg-color: gray;
	--ae-picker-label-color: var(--mat-sys-on-tertiary);
	--ae-picker-icon-bg-color: var(--mat-sys-on-tertiary-fixed);
	--ae-picker-option-bg-color: var(--mat-sys-tertiary-container);
	--ae-picker-option-active-bg-color: var(--mat-sys-tertiary-fixed);
	--ae-picker-option-focused-bg-color: var(--mat-sys-tertiary-fixed-dim);
}
