/* You can add global styles to this file, and also import other style files */
/*Old imports in angular.json
"src/styles/material-custom-theme.scss",
"src/styles/bootstrap_custom_build.scss",
*/

@import './styles/app_presets';
@import './styles/bootstrap_custom_build.scss';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import './styles/angular-editor';

@import './styles/material-theme';
@import './styles/angular-material';
@import './styles/tan_tables';

html {
	--done: green;
	--partial: #df6f0e;
	--text-information: #448aff;
	--text-hint: light-dark(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5));
	--table-even-row: rgb(241, 230, 226);
	--icon-inverse: rgba(255, 255, 255, 1);
	--icon-inactive-inverse: rgba(255, 255, 255, 0.3);

	--sidenav-width: 400px;
	--toolbar-height: 48px;
	@media only screen and (min-width: 600px) {
		--toolbar-height: 56px;
	}
	overflow-y: hidden;
}

body {
	font-size: 16px;
	height: 100%;

	// Bonus points
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--mat-sys-primary);
	}
}

label {
	margin-bottom: 0; // 2
}

a:not(.mat-mdc-button):not(.mat-mdc-raised-button):not(.mat-mdc-fab):not(.mat-mdc-mini-fab):not([mat-list-item]):not(.mat-tab-link) {
	color: var(--mat-sys-tertiary); // 3
}

ul {
	list-style: none;
	padding-left: 0;
}
ol {
	list-style: none;
	padding-left: 0;
}

.no-focus-outline a:focus,
.no-focus-outline button:focus {
	outline: none;
}

.clearfix {
	overflow: auto;
	zoom: 1;
}

#main_content {
	margin-top: var(--toolbar-height);
	margin-bottom: 60px;
	width: 100%;
}

.page_container {
	padding: $BS-SPACER-SIZE;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.page_container_small {
	padding: $BS-SPACER-SIZE;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

@media only screen and (min-width: 767px) {
	.page_container_small {
		padding-top: $BS-SPACER-SIZE;
		padding-bottom: $BS-SPACER-SIZE;
		width: 80%;
	}
}

.sidebarSpinner {
	display: block;
	width: 24px;
}

.fill_remaining_space {
	// This fills the remaining space, by using flexbox.
	// Every toolbar row uses a flexbox row layout.
	flex: 1 1 auto;
}

.button-unstyled {
	border: none;
	color: unset;
	padding: 0px;
	margin: 0px;
	background-color: transparent;
	font-weight: inherit;
}

#fab {
	position: fixed;
	bottom: 30px;
	right: 30px;
}

@media only screen and (min-width: 600px) {
	#fab {
		bottom: 50px;
		right: 50px;
	}
}

#centerInViewWToolbar {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - var(--toolbar-height));
}

// GENERAL

.m_pointer {
	cursor: pointer;
}

// TEXT MODIFIERS

.m_use_line_breaks {
	white-space: pre-line;
}

.m_t_link {
	text-decoration: underline;
	cursor: pointer;
}

.m_t_nowrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Text presets
.m_t_header {
	color: var(--mat-sys-on-background);
	font-size: 1.5rem;
	line-height: 1.5rem;
	font-family: Raleway;
	// font-weight: bold;
}

.m_t_subheader {
	color: var(--mat-sys-on-background);
	font-size: 1.1rem;
	line-height: 1.1rem;
	font-family: Raleway;
	// font-weight: bold;
}

.m_t_title_big {
	color: var(--mat-sys-on-background);
	font-size: 1.1rem;
	line-height: 1.4rem;
	font-family: Roboto;
	font-weight: bold;
}

.m_t_title {
	color: var(--mat-sys-on-background);
	font-size: 0.85rem;
	line-height: 0.955rem;
	font-family: Roboto;
	font-weight: bold;
}

.m_t_subtitle {
	color: var(--mat-sys-secondary);
	font-size: 0.82rem;
	line-height: 0.85rem;
	font-family: Roboto;
	font-weight: bold;
}

.m_t_paragraph_big {
	color: var(--mat-sys-secondary);
	font-size: 0.85rem;
	line-height: 0.95rem;
	font-family: Roboto;
}

.m_t_paragraph {
	color: var(--mat-sys-secondary);
	font-size: 0.75rem;
	line-height: 0.8rem;
	font-family: Roboto;
}

.m_t_small {
	font-family: Roboto;
	color: var(--mat-sys-secondary);
	font-size: 0.75rem;
	line-height: 0.75rem;
}

.m_t_hint {
	color: var(--text-hint);
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-family: Roboto;
}

.m_t_info {
	font-family: Roboto-condensed;
	color: var(--text-information);
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: bold;
}

.m_t_warn {
	color: var(--mat-sys-error);
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-family: Roboto;
}

// FONTS

.m_t_f_condensed {
	font-family: Roboto-condensed;
}

// COLORS
.m_t_c_info {
	color: var(--text-information) !important;
}

.m_t_c_done {
	color: var(--done) !important;
}

.m_t_c_warn {
	color: var(--mat-sys-error) !important;
}

.m_t_c_primary {
	color: var(--mat-sys-primary) !important;
}
.m_t_c_on_primary {
	color: var(--mat-sys-on-primary) !important;
}
.m_t_c_on_primary_container {
	color: var(--mat-sys-on-primary-container) !important;
}
.m_t_c_primary_inverse {
	color: var(--mat-sys-inverse-primary) !important;
}

.m_t_c_accent {
	color: var(--mat-sys-tertiary) !important;
}

.m_t_c_partial {
	color: var(--partial) !important;
}

.m_t_c_icon {
	color: var(--icon) !important;
}

.m_t_c_icon_inverse {
	color: var(--icon-inverse) !important;
}

.m_t_c_icon_inactive_inverse {
	color: var(--icon-inactive-inverse) !important;
}

.m_t_c_disabled {
	color: var(--disabled) !important;
}

.m_t_c_hint {
	color: var(--text-hint);
}

// OTHERS
.m_t_bold {
	font-weight: bold;
}

// Mat design card box shadows
.m_card_1 {
	box-shadow:
		0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
}

.m_card_2 {
	box-shadow:
		0 3px 6px rgba(0, 0, 0, 0.16),
		0 3px 6px rgba(0, 0, 0, 0.23);
}

.m_card_3 {
	box-shadow:
		0 10px 20px rgba(0, 0, 0, 0.19),
		0 6px 6px rgba(0, 0, 0, 0.23);
}

.m_card_4 {
	box-shadow:
		0 14px 28px rgba(0, 0, 0, 0.25),
		0 10px 10px rgba(0, 0, 0, 0.22);
}

.m_card_5 {
	box-shadow:
		0 19px 38px rgba(0, 0, 0, 0.3),
		0 15px 12px rgba(0, 0, 0, 0.22);
}

.json_pre {
	border: 2px solid #ccc;
	padding: 5px;
	max-height: 80vh;
	width: 100%;
	font-family: 'Lucida Console', Monaco, monospace;
	font-size: 0.75rem;
	line-height: 0.82rem;

	.json_key {
		color: black;
	}

	.json_string {
		color: green;
	}

	.json_number {
		color: darkorange;
	}

	.json_boolean {
		color: blue;
	}

	.json_null {
		color: red;
	}
}

.angular-editor-toolbar {
	font-size: 1em !important;
}

angular-editor.auto-show-toolbar {
	angular-editor-toolbar {
		display: none;
	}
}

angular-editor.auto-show-toolbar:focus-within {
	angular-editor-toolbar {
		display: block;
	}
}
