// bootstrap// https://www.amadousall.com/the-good-parts-of-bootstrap-4-you-are-missing-in-your-angular-material-projects/
@import '../../node_modules/bootstrap/scss/functions';

@import './bootstrap_variables';

@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/type';

@import './bootstrap_sizing';

.gap-1 {
	gap: ($spacer * 0.25);
}

.gap-2 {
	gap: ($spacer * 0.5);
}

.gap-3 {
	gap: ($spacer * 1);
}

.gap-4 {
	gap: ($spacer * 1.5);
}

.gap-5 {
	gap: ($spacer * 2);
}

.gap-6 {
	gap: ($spacer * 3);
}
