.mat-secondary {
	--mat-sys-primary: var(--mat-sys-secondary);
	--mat-sys-on-primary: var(--mat-sys-on-secondary);
	--mat-sys-primary-container: var(--mat-sys-secondary-container);
	--mat-sys-on-primary-container: var(--mat-sys-on-secondary-container);
}
.mat-tertiary {
	--mat-sys-primary: var(--mat-sys-tertiary);
	--mat-sys-on-primary: var(--mat-sys-on-tertiary);
	--mat-sys-primary-container: var(--mat-sys-tertiary-container);
	--mat-sys-on-primary-container: var(--mat-sys-on-tertiary-container);
}

.mat-error {
	--mat-sys-primary: var(--mat-sys-error);
	--mat-sys-on-primary: var(--mat-sys-on-error);
	--mat-sys-primary-container: var(--mat-sys-error-container);
	--mat-sys-on-primary-container: var(--mat-sys-on-error-container);
}

// MAT TABLES
[mat-table] {
	& th {
		background: var(--mat-sys-primary-fixed) !important;
		position: sticky;
		top: var(--toolbar-height);
		z-index: 10;

		&:not(:first-of-type) {
			padding-left: 4px;
		}

		&:not(:last-of-type) {
			padding-right: 4px;
		}

		.headerContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: start;

			.filterField {
				width: 60px;
				display: block;

				.mat-mdc-form-field-subscript-wrapper {
					display: none !important;
				}

				.mat-mdc-form-field-infix {
					min-height: 1.2em;
					padding-top: 0px !important;
					padding-bottom: 0px !important;
				}

				.mat-mdc-text-field-wrapper {
					padding-left: 0px !important;
					padding-right: 0px !important;
				}
			}
		}
	}

	& td {
		&:not(:first-of-type) {
			padding-left: 4px;
		}

		&:not(:last-of-type) {
			padding-right: 4px;
		}
	}
}

.tableRowEven {
	background-color: #dddddd !important;
}

// MATERIAL DESIGN OVERRIDES

// TODO bug in angular material textarea 15.0.3? Textarea not outline doesn't show label.

.mat-select-text-area-height-20 {
	.mat-mdc-select-arrow-wrapper {
		height: 20px;
	}

	.mat-mdc-select-value {
		line-height: 20px;
	}
}

// needed in sidebar otherwise the mat badge icon is cut by the containing span
#expansion .mat-expansion-panel-header > span.mat-content:has(.mat-badge-content) {
	overflow: visible;
}

// needed in sidebar otherwise the mat badge icon is cut by the containing span
#expansion .mat-expansion-panel-content span.mdc-list-item__content:has(.mat-badge-content) {
	overflow: visible;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
	display: unset;
}

// ----------------

.matFormFieldNoSubscript {
	.mat-mdc-form-field-subscript-wrapper {
		display: none !important;
	}
}

.matFormFieldNoVerticalPadding {
	.mat-mdc-form-field-infix {
		min-height: 1.2em;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}

.matFormFieldNoHorizontalPadding {
	.mat-mdc-text-field-wrapper {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

.matFormFieldNoSetWidth {
	.mat-mdc-form-field-infix {
		width: auto !important;
	}
}

.matButtonNoMinWidth {
	min-width: auto !important;
}

.bottomSheetWidth80percent {
	width: 100%;

	.mat-bottom-sheet-container {
		width: 80vw !important;
		min-width: unset;
		margin-left: auto;
		margin-right: auto;
	}
}

.cdk-overlay-pane {
	width: unset !important;
	max-width: 85vw !important;
}

// .mat-mdc-select-panel {
// 	max-width: 85vw !important;
// }

.matSelectPanelMinWidth {
	min-width: 200px;
}

// inserted inside mat-option
.mdc-list-item__primary-text {
	width: 100% !important;
}

mat-checkbox.mat-checkbox-no-padding-margin {
	--mdc-checkbox-state-layer-size: 18px;
	--mat-checkbox-touch-target-display: none;
}

// Mat Design override
.specialDate {
	background-color: red !important;
}

.selectExtPanelStyle {
	min-width: 100% !important;
}

.selectExtWithSearchPanelStyle {
	min-width: 100% !important;
	padding-top: 0px !important;
}

.cdk-overlay-container {
	z-index: 999 !important;
}
